<script>
import Layout from "../../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";

export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      del: "",
      tickets: [],
      issue: [],
      customer: [],
      Tickets: {},
      page: 1,
      limit: 20,
      tot_pages: 0,
      pageres: 1,
      limitres: 20,

      searchTickets: null,
      TicketsSearchMode: false,
      ticket_resolutions: [],
      TicketResolutions: {},
      emp: [],
      ticket_id: 0,

      searchTicketResolutions: null,
      TicketResolutionsSearchMode: false,
      // appprices:[]
    };
  },
  methods: {
    getSwitch(searchIn, remark) {
      console.log("*********", remark);
      this.http
        .put("tickets", searchIn.id, {
          remark: remark,
        })

        .then(() => {
          this.get(this.page);
        });
    },
    gitresolution(searchIn) {
      this.ticket_id = searchIn.id;

      this.http.get("ticket-resolutions/" + searchIn.id).then((res) => {
        this.ticket_resolutions = res.data;
      });
    },
    // getres(page){
    // console.log(page)
    //  this.http.post('ticket-resolutions/pagination',{
    //   limit:this.limitres,
    //   page:this.page,
    //   ticket_id:this.tickets.id
    //  }).then((res)=>{
    //   this.page = page
    // this.tot_pages = Math.ceil(res.tot/this.limit)
    // this.ticket_resolutions = res.data
    //    })}

    // ,
    addTicketResolutions() {
      setTimeout(() => {
        document.querySelector(".btn-close").click();
      }, "200");

      //  var x = document.getElementsByClassName('modal')
      //  x.classList.remove("modal fade show")
      //  x.classList.add('modal fade hide')

      var data = {
        title: "popups.addticketresolution",
        inputs: [
          {
            model: "emp_id",
            type: "select",
            options: (() => {
              return this.emp.map((type) => {
                return { value: type.id, label: type.name };
              });
            })(),
            label: "popups.employee",
          },

          {
            model: "report",
            type: "ckeditor",
            label: "popups.des",
          },
        ],
        buttons: [
          {
            text: "popups.add",
            closer: true,
            color: "primary",
            handler: (obj) => {
              console.log(this.$route.params.id);
              obj.ticket_id = this.ticket_id;
              // document.querySelector('modal fade show').remove()
              this.http.post("ticket-resolutions", obj).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    getTicketResolutions() {
      this.http.get("ticket-resolutions").then((res) => {
        this.ticket_resolutions = res.data;
      });
    },
    gitemp() {
      this.http.get("emps").then((res) => {
        this.emp = res.data;
      });
    },
    addTickets() {
      var data = {
        title: "popups.addticket",
        inputs: [
          {
            model: "issue_type_id",
            type: "select",
            options: (() => {
              return this.issue.map((type) => {
                return { value: type.id, label: type.name };
              });
            })(),
            label: "popups.issuetype",
          },

          {
            model: "customer_id",
            type: "select",
            options: (() => {
              return this.customer.map((type) => {
                return { value: type.id, label: type.name };
              });
            })(),
            label: "popups.customer",
          },

          {
            model: "description",
            type: "ckeditor",
            label: "popups.des",
          },
          {
            model: "priority",
            type: "select",
            options: [
              { label: "High", value: 1 },
              { label: "medium", value: 2 },
              { label: "low", value: 3 },
            ],
            label: "popups.priority",
          },
        ],
        buttons: [
          {
            text: "popups.add",
            closer: true,
            color: "primary",
            handler: (obj) => {
              this.http.post("tickets", obj).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    editTickets(searchIn) {
      var data = {
        title: "popups.editticket",
        inputs: [
          {
            model: "issue_type_id",
            type: "select",
            options: (() => {
              return this.issue.map((type) => {
                return { value: type.id, label: type.name };
              });
            })(),
            label: "popups.issuetype",
            value: searchIn.issue_type_id,
          },

          {
            model: "customer_id",
            type: "select",
            options: (() => {
              return this.customer.map((type) => {
                return { value: type.id, label: type.name };
              });
            })(),
            label: "popups.customer",
            value: searchIn.customer_id,
          },
          {
            model: "status",
            type: "select",
            label: "popups.status",
            options: [
              { label: "UnFinished", value: 0 },
              { label: "Finished ", value: 1 },
            ],
          },

          {
            model: "description",
            type: "ckeditor",
            label: "popups.des",
            value: searchIn.description,
          },
          {
            model: "priority",
            type: "select",
            options: [
              { label: "High", value: 1 },
              { label: "medium", value: 2 },
              { label: "low", value: 3 },
            ],
            label: "popups.priority",
            value: searchIn.priority,
          },
        ],
        buttons: [
          {
            text: "popups.edit",
            closer: true,
            color: "primary",
            handler: (obj) => {
              this.http.put("tickets", searchIn.id, obj).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    search() {
      this.TicketsSearchMode = true;
      this.tot_pages = 0;
      this.http
        .post("tickets/search", {
          search: this.searchModel,
          col: "name",
          limit: this.limit,
          page: this.page,
        })
        .then((res) => {
          var data = res.data;
          var tickets = [];
          for (let i = 0; i < data.length; i++) {
            for (let j = 0; j < data[i].tickets.length; j++) {
              tickets.push(data[i].tickets[j]);
            }
          }
          this.tickets = tickets;

          // console.log('g' );
          // this.tickets = res.data.map(element =>
          // this.tickets = element.tickets,
          // console.log("xxxxxxx",this.tickets)).filter(z=>this.tickets = z.tickets)

          console.log("*********** ticketsbbb", this.tickets);
        });
    },
    cancelappsearchMode() {
      this.searchModel = "";
      this.TicketsSearchMode = false;
      this.get(this.page);
    },
    getTickets() {
      this.http.get("tickets").then((res) => {
        this.tickets = res.data;
      });
    },
    gitIssue() {
      this.http.get("issue-types").then((res) => {
        this.issue = res.data;
      });
    },
    gitcustomer() {
      this.http.get("customers").then((res) => {
        this.customer = res.data;
      });
    },
    deleteTickets(searchIn) {
      var data = {
        title: "popups.deleteemp",
        msg: "popups.delmessage",
      };
      this.popup.confirm(data).then((resp) => {
        if (resp) {
          this.http.delete("tickets", searchIn.id).then(() => {
            this.get(this.page);
          });
        }
      });
    },

    get(page) {
      console.log(page);
      this.http
        .post("tickets/pagination", {
          limit: this.limit,
          page: page,
        })
        .then((res) => {
          this.page = page;
          this.tot_pages = Math.ceil(res.tot / this.limit);
          this.tickets = res.data;
        });
    },
  },

  created() {
    this.get(1);
    // this.getres()
    // this.getTickets();

    this.gitIssue();
    this.gitcustomer();
    this.getTicketResolutions();
    this.gitemp();
  },
};
</script>

<template>
  <Layout>
    <PageHeader
      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
      :title="$t('menu.menuitems.tickets.text')"
    />

    <!-- Start search box -->
    <div class="row">
      <div class="col-6">
        <div class="search-box chat-search-box w-50">
          <div class="position-relative">
            <input
              @keyup="search()"
              type="text"
              class="form-control fa-lg text-light"
              :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
              :placeholder="$t('popups.search')"
              v-model="searchModel"
              style="background-color: #2a3042 !important"
            />
            <i class="bx bx-search-alt search-icon text-light"></i>
          </div>
        </div>
      </div>
      <div class="col-6" style="display: flex; justify-content: flex-end">
        <button
          class="btn-close"
          v-if="TicketsSearchMode"
          @click="cancelappsearchMode()"
        ></button>

        <button
          type="button"
          @click="addTickets()"
          class="btn btn-light float-end mb-4"
        >
          <span
            class="bx bxs-plus-circle float-end fa-2x"
            style="color: #2a3042 !important"
          ></span>
        </button>
      </div>
    </div>
    <!-- end search box -->

    <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table">
      <thead>
        <tr
          class="text-light text-center"
          style="background-color: #2a3042 !important"
        >
          <th scope="col">{{ $t("tickets.id") }}</th>
          <th scope="col">{{ $t("tickets.issuetype") }}</th>
          <th scope="col">{{ $t("tickets.customer") }}</th>
          <th scope="col">{{ $t("tickets.description") }}</th>
          <th scope="col">{{ $t("tickets.status") }}</th>
          <th scope="col">{{ $t("tickets.priority") }}</th>
          <th scope="col" style="width: 2%">{{ $t("tickets.created") }}</th>

          <th scope="col">{{ $t("tickets.updated") }}</th>
          <th scope="col">{{ $t("tickets.operations") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(searchIn, index) in tickets"
          :key="searchIn"
          class="text-center"
        >
          <td>{{ index + 1 }}</td>
          <td>{{ searchIn.issue_type?.name }}</td>
          <td>{{ searchIn?.customer?.name }}</td>
          <td v-html="searchIn.description"></td>
          <td>
            <h6 v-if="searchIn.status == 0" class="text-danger">
              {{ $t("popups.underprogress") }}
            </h6>
            <span v-else class="text-success">{{ $t("popups.Finished") }}</span>
          </td>
          <td>
            <h6 v-if="searchIn.priority == 1" class="text-danger">
              {{ $t("popups.high") }}
            </h6>
            <span v-if="searchIn.priority == 2" class="text-warning">{{
              $t("popups.medium")
            }}</span>
            <h6 v-if="searchIn.priority == 3" class="text-success">
              {{ $t("popups.low") }}
            </h6>
          </td>
          <td>{{ searchIn?.created.split("T")[0] }}</td>
          <td>{{ searchIn?.updated.split("T")[0] }}</td>

          <td class="d-flex justify-content-center">
            <button
              @click="getSwitch(searchIn, 1)"
              v-if="searchIn.remark == 0"
              class="btn btn-warning btn-sm me-2"
            >
              {{ $t("popups.waiting") }}
            </button>
            <div v-if="searchIn.remark == 1">
              <button
                @click="getSwitch(searchIn, 2)"
                class="btn btn-danger btn-sm me-2 mt-2"
              >
                {{ $t("popups.rejected") }}
              </button>
            </div>
            <div v-if="searchIn.remark == 2">
              <button
                type="button"
                @click="getSwitch(searchIn, 1)"
                class="btn btn-success btn-sm me-2 mt-2"
              >
                {{ $t("popups.accepted") }}
              </button>
            </div>
            <a
              class="btn btn-primary me-2"
              data-bs-toggle="modal"
              data-bs-target="#ticket"
              role="button"
              @click="gitresolution(searchIn)"
              >{{ $t("popups.resolutions") }}
            </a>
            <a
              class="btn btn-primary me-2"
              @click="editTickets(searchIn)"
              href="javascript: void(0);"
              role="button"
              >{{ $t("popups.edit") }}</a
            >
            <a
              class="btn btn-danger me-2"
              href="javascript: void(0);"
              role="button"
              @click="deleteTickets(searchIn)"
              >{{ $t("popups.delete") }}</a
            >
          </td>
        </tr>
      </tbody>
    </table>
    <!-- end  table -->
    <!-- Button trigger modal -->

    <!-- Modal -->
    <div
      class="modal fade"
      id="ticket"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              {{ $t("popups.ticketresolution") }}
            </h5>
            <button
              type="button"
              class="btn-close close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <button
              type="button"
              @click="addTicketResolutions()"
              class="btn btn-light float-end mb-4"
            >
              <span
                class="bx bxs-plus-circle float-end fa-2x"
                style="color: #2a3042 !important"
              ></span>
            </button>

            <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table">
              <thead>
                <tr
                  class="text-light text-center"
                  style="background-color: #2a3042 !important"
                >
                  <th scope="col">{{ $t("tickets.id") }}</th>
                  <th scope="col">{{ $t("tickets.ticket") }}</th>
                  <th scope="col">{{ $t("tickets.employee") }}</th>
                  <th scope="col">{{ $t("tickets.report") }}</th>
                  <th scope="col">{{ $t("tickets.created") }}</th>
                  <!-- <th scope='col'>Updated</th> -->
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(app, index) in ticket_resolutions"
                  :key="app"
                  class="text-center"
                >
                  <td>{{ index + 1 }}</td>
                  <td>{{ app?.ticket?.issue_type?.name }}</td>
                  <td v-if="app.emp">{{ app.emp.name }}</td>
                  <td v-html="app.report"></td>
                  <td>{{ app.created.split("T")[0] }}</td>
                  <!-- <td>{{app.updated}}</td> -->
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <!--end-->
      <!-- Modal -->
      <div
        class="modal fade w-100"
        id="Add"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div class="section">
                <h1>Basic Information</h1>

                <div class="row"></div>
                <div class="row">
                  <div class="col-12 col-md-6">
                    <label class="form-label mt-2">
                      Programming Language
                    </label>
                    <select class="form-select" v-model="c">
                      <option>Python</option>
                      <option>PHP</option>
                      <option>NodeJS</option>
                      <option>Java</option>
                    </select>
                  </div>
                  <div class="col">
                    <label class="form-label mt-2">Icon </label>
                    <input type="file" class="form-control" />
                  </div>
                </div>
              </div>
            </div>

            <br /><!--end--><br />
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Add
            </button>
            <button type="button" class="btn btn-primary">Close</button>
          </div>
        </div>
      </div>
    </div>
    <!--end-->
    <!--   Apps  Pagination     -->
    <div v-if="tot_pages == 20">
      <ul class="pagination pagination-rounded justify-content-center mb-2">
        <li class="page-item" :class="{ disabled: page == 1 }">
          <a
            class="page-link"
            href="javascript: void(0);"
            @click="get(page - 1)"
            aria-label="Previous"
          >
            <i class="mdi mdi-chevron-left"></i>
          </a>
        </li>
        <li
          class="page-item"
          :class="{ active: p == page }"
          v-for="p in tot_pages"
          :key="p"
        >
          <a class="page-link" href="javascript: void(0);" @click="get(p)">{{
            p
          }}</a>
        </li>

        <li class="page-item" :class="{ disabled: page == total_pages }">
          <a
            class="page-link"
            href="javascript: void(0);"
            @click="get(page + 1)"
            aria-label="Next"
          >
            <i class="mdi mdi-chevron-right"></i>
          </a>
        </li>
      </ul>
    </div>
    <!--end-->
  </Layout>
</template>
<style scoped>
.close {
  position: relative;
  right: 317px;
  top: 6px;
  font-size: 15px;
}
</style>
